import React from "react";
import { Skeleton } from '@mui/material';
import ViewReportButton from "./UI/ButtonWithGradientIcon";
import InsightsIcon from '@mui/icons-material/Insights';
import { generateReport } from "./utils/generateReport";

const GenerativeAI = ({ AICombinedData, AILoading, counters }) => {
  const isReportAvailable = AICombinedData.paraphrasedScript.length > 0;

  const handleReportGeneration = () => {
    generateReport({ AICombinedData, counters });
  }

  return(
    <div className='flex flex-1 flex-col w-full h-full md:px-36 sm:px-8 py-10 gap-10' >
      <div className='flex-1 flex w-full h-full flex-col md:flex-row gap-10 '>
        <div className='flex flex-col flex-1 h-full bg-gradient-to-tr from-teal-800 to-gray-800 pt-5 pb-6 px-[33px] rounded-md gap-4'>
          <strong className="text-center self-center">
            Paraphrased Script
          </strong>
          {AILoading.scriptLoading ? (
            <Skeleton variant="rounded" height="100%" width="100%" />
          ) : (
            <textarea
              id="Paraphrased Text"
              className=" w-full text-white outline-none h-full"
              readOnly
              value={AICombinedData.paraphrasedScript.length > 0 ? AICombinedData.paraphrasedScript : 'No Script available'}
            />
          )}
        </div>
        <div className='flex flex-col flex-1 h-full bg-gradient-to-tr from-teal-800 to-gray-800 pt-5 pb-6 px-[33px] rounded-md gap-4'>
        <strong className="text-center self-center">
            Example Question
          </strong>
          {AILoading.questionLoading ? (
            <Skeleton variant="rounded" height="100%" width="100%" />
          ) : (
            <textarea
              id="Example Question"
              className=" w-full text-white outline-none h-full"
              readOnly
              value={AICombinedData.generatedQuestions.length > 0 ? AICombinedData.generatedQuestions : 'No questions available'}
            />
          )}
        </div>
      </div>
      <div className="w-full relative flex justify-center items-center gap-16">
        <ViewReportButton 
          label="View Report"
          IconComponent={InsightsIcon}
          gradient={true} 
          onClick={handleReportGeneration}
          AICombinedData={AICombinedData}
        />
      </div>
    </div>
  );
}

export default GenerativeAI;