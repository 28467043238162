import React, { useState, useRef, useEffect } from 'react';
import Header from './Header';
import BodyPoseFeedContainer from './BodyPoseFeedContainer';
import SpeechContent from './SpeechContent';
import PersistentDrawerContainer from './PersistentDrawer';
import GenerativeAI from './GenerativeAI';
import { motion } from 'framer-motion';
import { loadMediaPipeModules } from './utils/moduleLoader';
import ApiSnackbarAlert from './UI/ReuableSnackbarAlert';

function AppPage() {
  const [alignment, setAlignment] = useState('stop');
  const [tabValue, setTabValue] = useState(0); // Manage active tab state
  const containerRef = useRef(null);
  const [deleteRequested, setDeleteRequested] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [initialAnimationComplete, setInitialAnimationComplete] = useState(false);
  const [modulesLoaded, setModulesLoaded] = useState(false);
  const [AICombinedData, AIsetCombinedData] = useState({ 
    target_user: '',
    presenting_scenario: '',
    purpose: '',
    user_script: '',
    paraphrasedScript: '', 
    generatedQuestions: [],
    pauseFillersCount: {},
    scriptAdvice: '',
  });
  const [counters, setCounters] = useState({
    armsCrossed: 0,
    handsInPocket: 0,
    lookingDown: 0,
    eyeContact: 0,
    headMove: 0,
  });

  const [emotionData, setEmotionData] = useState({
  });


  const [AILoading, AIsetLoading] = useState({
    scriptLoading: false,
    questionLoading: false,
  });
  const [apiSnackbarOpen, setApiSnackbarOpen] = useState(false);
  const [apiSnackbarLabel, setApiSnackbarLabel] = useState('');
  const [apiSnackbarSeverity, setApiSnackbarSeverity] = useState('success');

  const setApiSnackbar = ({ open, label, severity }) => {
    console.log('Setting Snackbar:', { open, label, severity });
    setApiSnackbarOpen(open);
    setApiSnackbarLabel(label);
    setApiSnackbarSeverity(severity);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const changeTab = (newValue) => {
    setTabValue(newValue);
  };

  const handleDelete = () => {
    setDeleteRequested(true); // Trigger delete action

  };

  useEffect(() => {
    setIsInitialRender(false);
  }, []);

  // Load scripts after initial animation completes
  useEffect(() => {
    if (initialAnimationComplete) {
      const initializeModules = async () => {
        try {
          await loadMediaPipeModules();
          setModulesLoaded(true); // Set to true only after successful loading
        } catch (error) {
          console.error("Error loading MediaPipe modules:", error);
          setModulesLoaded(false); // Optionally handle failure state
        }
      };
  
      initializeModules();
    }
  }, [initialAnimationComplete]);
  
  return (
    <div className='relative h-screen w-full z-0 flex flex-col' >
      <Header 
        tabValue={tabValue} 
        handleTabChange={handleTabChange} 
      />
      <main id="header" className="overflow-hidden flex-1">
        {tabValue === 0 && (
          <div
            key="tab0"
            className="flex flex-col md:flex-row gap-2 md:px-36 sm:px-8 py-2 h-full"
          >
            <motion.div
              className='flex-1'
              initial={isInitialRender ? { opacity: 0, y: 50, x: -70 } : undefined}
              animate={{ opacity: 1, y: 0, x: 0 }}
              transition={{ duration: 0.5 }}
              onAnimationComplete={() => setInitialAnimationComplete(true)}
            >
              <BodyPoseFeedContainer 
                alignment={alignment} 
                setAlignment={setAlignment} 
                deleteRequested={deleteRequested}
                setDeleteRequested={setDeleteRequested}
                transcript={transcript}
                AIsetCombinedData={AIsetCombinedData}
                AIsetLoading={AIsetLoading}
                changeTab={changeTab}
                tabValue={tabValue}
                initialAnimationComplete={initialAnimationComplete}
                modulesLoaded={modulesLoaded}
                setApiSnackbar={setApiSnackbar}
                counters={counters}
                setCounters={setCounters}
                emotionData={emotionData}
                setEmotionData={setEmotionData}
              />
            </motion.div>
            <motion.div
              className='flex-1'
              initial={isInitialRender ? { opacity: 0, y: 50, x: 70 } : undefined}
              animate={{ opacity: 1, y: 0, x: 0 }}
              transition={{ duration: 0.5 }}
            >
              <SpeechContent 
                alignment={alignment} 
                onDeleteRequest={handleDelete}
                transcript={transcript}
                setTranscript={setTranscript}
                initialAnimationComplete={initialAnimationComplete}
              />
            </motion.div>
          </div>
          )}
          <div
            className="flex flex-1 w-full h-full"
            ref={containerRef}
            style={{ display: tabValue === 1 ? 'flex' : 'none' }}
          >
            <PersistentDrawerContainer 
              containerRef={containerRef}
              AICombinedData={AICombinedData}
              AIsetCombinedData={AIsetCombinedData}
              AIsetLoading={AIsetLoading}
            >
              <div
                className='flex-1'
              >
                <GenerativeAI
                  AICombinedData={AICombinedData}
                  AILoading={AILoading}
                  counters={counters}
                />
              </div>
            </PersistentDrawerContainer>
          </div>
        <>
          <ApiSnackbarAlert
            open={apiSnackbarOpen}
            label={apiSnackbarLabel}
            severity={apiSnackbarSeverity}
            autoHideDuration={3500}
            vertical="top" // Set the vertical position to top
            horizontal="center"
            onClose={ () => setApiSnackbarOpen(false)}
          />
        </>
      </main>
    </div>
  );
}

export default AppPage;
