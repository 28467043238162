import React, { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import darkTheme from './utils/DarkTheme';
import { ThemeProvider } from '@mui/material/styles';
import Slider from '@mui/material/Slider';
import RegenerateButton from './UI/ButtonWithGradientIcon';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import config from '../config';
import RegenerateAlert from './UI/ReuableSnackbarAlert';

// Define the width of the drawer
const drawerWidth = 200;
const headerWidth = 40;

// Style for the main content area
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth,
    }),
  }),
);

// Use shouldForwardProp to exclude unwanted props from being passed to the DOM
const DrawerStyled = styled(Drawer, { shouldForwardProp: (prop) => !['containerHeight', 'containerTop'].includes(prop) })(
  ({ theme, containerHeight, containerTop }) => ({
    width: headerWidth,
    flexShrink: 0,
    height: containerHeight,
    top: containerTop,
    '& .MuiDrawer-paper': {
      width: drawerWidth + headerWidth,
      boxSizing: 'border-box',
      height: containerHeight,
      top: containerTop,
      borderRight: '1px solid gray',
      backgroundColor: '#1f2937',
    },
  }),
);

const sliderLabelStyle = {
  fontSize: '0.8rem', // Font size
  fontWeight: '300', // Font weight (thin)
  fontStyle: 'italic', // Italic style
};

// Define the slider marks with the shared style applied
const sliderMarks = {
  length: [
    { value: 0, label: <span style={sliderLabelStyle}>Shorter</span> },
    { value: 1, label: <span style={sliderLabelStyle}>Remain</span> },
    { value: 2, label: <span style={sliderLabelStyle}>Longer</span> },
  ],
  tone: [
    { value: 0, label: <span style={sliderLabelStyle}>Formal</span> },
    { value: 1, label: <span style={sliderLabelStyle}>Remain</span> },
    { value: 2, label: <span style={sliderLabelStyle}>Casual</span> },
  ],
  complexity: [
    { value: 0, label: <span style={sliderLabelStyle}>Basic</span> },
    { value: 1, label: <span style={sliderLabelStyle}>Remain</span> },
    { value: 2, label: <span style={sliderLabelStyle}>Advanced</span> },
  ],
};

export default function PersistentDrawerContainer({ children, containerRef, AICombinedData, AIsetCombinedData, AIsetLoading }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [containerHeight, setContainerHeight] = useState(null);
  const [containerTop, setContainerTop] = useState(0);
  const [lengthLevel, setLengthLevel] = useState(1);
  const [toneLevel, setToneLevel] = useState(1);
  const [complexityLevel, setComplexityLevel] = useState(1);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Function to handle regeneration
  const handleRegenerate = () => {
    const requiredKeys = [
      'paraphrasedScript', 
      'target_user', 
      'presenting_scenario', 
      'purpose'
    ];
    
    const hasUndefinedOrFalsyValues = requiredKeys.some(key => !AICombinedData[key]);
    
    if (hasUndefinedOrFalsyValues) {
      console.warn('Some required data is missing or falsy in AICombinedData:', AICombinedData);
      setSnackbarOpen(true);
      return;
    }

    AIsetLoading({
      questionLoading: false,
      scriptLoading: true,
    });

    const requestData = {
      target_user: AICombinedData.target_user,
      presenting_scenario: AICombinedData.presenting_scenario,
      purpose: AICombinedData.purpose,
      user_script: AICombinedData.paraphrasedScript,
      length_level: lengthLevel,
      tone_level: toneLevel,
      vocabulary_level: complexityLevel,
    };

    console.log('Request Data for regeneration:', requestData);

    fetch(`${config.API_BASE_URL}/reparaphrase-script/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestData),
    })
      .then(response => response.json())
      .then(data => {
        // Assuming AIsetCombinedData is a function to update the combined data state
        AIsetCombinedData(prevData => ({
          ...prevData,
          paraphrasedScript: data.reparaphrased_script,
        }));
      })
      .catch(error => {
        console.error('Error:', error);
      })
      .finally(() => {
        AIsetLoading({
          questionLoading: false,
          scriptLoading: false,
        });
      });
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const updateHeightAndTop = () => {
      if (containerRef.current) {
        setContainerHeight(containerRef.current.clientHeight);
        setContainerTop(containerRef.current.getBoundingClientRect().top);
      }
    };

    const resizeObserver = new ResizeObserver(updateHeightAndTop);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    // Update height and top on component mount
    updateHeightAndTop();

    // Cleanup event listener on component unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef]);

  return (
    <Box sx={{ display: 'flex', position: 'relative', width: '100%', height: '100%' }}>
      <ThemeProvider theme={darkTheme}>
        <DrawerStyled
          variant="persistent"
          anchor="left"
          open={open}
          containerHeight={containerHeight}
          containerTop={containerTop} // Pass the top position to DrawerStyled
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: theme.spacing(0, 1),
              ...theme.mixins.toolbar,
              borderBottom: '1px solid gray'
            }}
          >
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
            <strong className='pl-4 text-sky-300'>
              AI REGENERATION
            </strong>
          </Box>

          {/* Drawer content with sliders */}
          <Box sx={{ padding: theme.spacing(4) }}>
            <Box sx={{ marginBottom: theme.spacing(3) }}>
              <strong>Length</strong>
              <Slider
                value={lengthLevel}
                onChange={(e, newValue) => setLengthLevel(newValue)}
                defaultValue={1}
                step={1}
                marks={sliderMarks.length}
                min={0}
                max={2}
                valueLabelDisplay="off"
                color='primary'
                size='small'
              />
            </Box>

            <Box sx={{ marginBottom: theme.spacing(3) }}>
              <strong>Tone</strong>
              <Slider
                value={toneLevel}
                onChange={(e, newValue) => setToneLevel(newValue)}
                defaultValue={1}
                step={1}
                marks={sliderMarks.tone}
                min={0}
                max={2}
                valueLabelDisplay="off"
                color='primary'
                size='small'
              />
            </Box>

            <Box>
              <strong>Complexity</strong>
              <Slider
                value={complexityLevel}
                onChange={(e, newValue) => setComplexityLevel(newValue)}
                defaultValue={1}
                step={1}
                marks={sliderMarks.complexity}
                min={0}
                max={2}
                valueLabelDisplay="off"
                color='primary'
                size='small'
              />
            </Box>
          </Box>
          <div className='w-auto flex align-middle self-center'>
            <RegenerateButton 
              label="Regenerate" 
              IconComponent={AutoFixHighIcon} 
              gradient={true}
              onClick={handleRegenerate}
            />
          </div>
        </DrawerStyled>

        {!open && (
          <div
            style={{ top: containerTop, width: headerWidth }}
            className="w-5 border-r border-gray-500 fixed h-full bg-gray-800"
          >
            <IconButton
              color="primary"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              sx={{ position: 'absolute', top: 11, left: 0, zIndex: 1100, color: 'white' }}
            >
              <ChevronRightIcon />
            </IconButton>
          </div>
        )}
        <Main open={open} className='w-full h-full flex flex-col'>
          {children}
        </Main>
      </ThemeProvider>
      <RegenerateAlert
        open={snackbarOpen}
        label="No script available to regenerate!"
        severity= "warning"
        autoHideDuration={3000}
        onClose={ () => setSnackbarOpen(false)}
      />
    </Box>
  );
}
