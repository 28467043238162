import React, { useEffect, useRef } from 'react';

const WaveformVisualizer = React.memo(({ analyserRef, isActive, canvasRef }) => {
  const animationIdRef = useRef(null);

  useEffect(() => {
    console.log('WaveformVisualizer: isActive:', isActive);
    console.log('WaveformVisualizer: analyserRef:', analyserRef.current);
    if (!isActive || !analyserRef.current) {
      if (animationIdRef.current) {
        console.log('WaveformVisualizer: Stopping animation...');
        cancelAnimationFrame(animationIdRef.current); // Clean up animation if not active
        animationIdRef.current = null;
      }
      return;
    }
    console.log('WaveformVisualizer: Starting animation...');

    const canvas = canvasRef.current;
    if (!canvas) {
      console.log('WaveformVisualizer: canvasRef is null');
      return;
    }
    const canvasCtx = canvas.getContext('2d');
    const WIDTH = canvas.width;
    const HEIGHT = canvas.height;
    const bufferLength = analyserRef.current.fftSize;
    const dataArray = new Uint8Array(bufferLength);

    const draw = () => {
      if (!isActive) return;
      analyserRef.current.getByteTimeDomainData(dataArray);

      canvasCtx.fillStyle = '#0D1117';
      canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

      canvasCtx.lineWidth = 2;
      canvasCtx.strokeStyle = 'rgb(200, 0, 0)';

      canvasCtx.beginPath();
      const sliceWidth = WIDTH * 1.0 / bufferLength;
      let x = 0;

      for (let i = 0; i < bufferLength; i++) {
        const v = dataArray[i] / 128.0;
        const y = v * HEIGHT / 2;

        if (i === 0) {
          canvasCtx.moveTo(x, y);
        } else {
          canvasCtx.lineTo(x, y);
        }
        x += sliceWidth;
      }

      canvasCtx.lineTo(WIDTH, HEIGHT / 2);
      canvasCtx.stroke();

      animationIdRef.current = requestAnimationFrame(draw);
    };

    draw();

    return () => {
      if (animationIdRef.current) {
        console.log('WaveformVisualizer: Cleaning up animation...');
        cancelAnimationFrame(animationIdRef.current); // Clean up animation
        animationIdRef.current = null;
      } // Clean up animation
    };
  }, [isActive, analyserRef, canvasRef]);

  return null;
});

export default WaveformVisualizer;