import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { SvgIcon } from '@mui/material';

// Style for the gray button
const GrayButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText('#606060'), // Adjust text color for contrast
  backgroundColor: '#606060', // Gray color
  '&:hover': {
    backgroundColor: '#505050', // Darker gray on hover
  },
  textTransform: 'none', // No all caps
  fontFamily: 'Helvetica Neue, Arial, sans-serif',
  fontSize: '0.875rem',
  fontWeight: 400,
}));

// Gradient icon component with unique gradient ID
function GradientIcon({ IconComponent, gradientId }) {
  return (
    <SvgIcon viewBox="0 0 24 24">
      <defs>
        <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor="#4C83EF" />
          <stop offset="100%" stopColor="#D96570" />
        </linearGradient>
      </defs>
      <IconComponent sx={{ fill: `url(#${gradientId})` }} />
    </SvgIcon>
  );
}

// Main reusable button component
function ReusableButton({ label, IconComponent, gradient = false, onClick }) {
  // Generate a unique ID for each gradient instance
  const uniqueGradientId = `gradient-${Math.random().toString(36).substr(2, 9)}`;

  return (
    <GrayButton
      variant="contained"
      startIcon={gradient ? <GradientIcon IconComponent={IconComponent} gradientId={uniqueGradientId} /> : <IconComponent />}
      onClick={onClick}
    >
      {label}
    </GrayButton>
  );
}

export default ReusableButton;
