import React, { useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const ResuableSnackbarAlert = ({ open, label, severity, autoHideDuration = 2000, onClose, vertical = 'bottom', horizontal = 'left' }) => {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose(); // Notify parent to close Snackbar
  };

  useEffect(() => {
    if (open) {
      // Optionally, you can perform any side-effects here when Snackbar opens
    }
  }, [open]);

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        sx={{ width: '100%' }}
      >
        {label}
      </Alert>
    </Snackbar>
  );
};

export default ResuableSnackbarAlert;
