// SpectrogramGenerator.jsx
import {useEffect} from 'react';

const SpectrogramGenerator = ({ audioBlob, setAudioUrl, canvasRef }) => {

  useEffect(() => {
    if (!audioBlob) return;

    const canvas = canvasRef.current;
    const canvasCtx = canvas.getContext('2d');
    const audioContext = new (window.AudioContext || window.webkitAudioContext)();

    const reader = new FileReader();
    reader.onload = (event) => {
      audioContext.decodeAudioData(event.target.result)
        .then((buffer) => {
          const rawData = buffer.getChannelData(0);
          const samples = Math.min(2048, rawData.length);  // Number of samples to visualize
          const step = Math.floor(rawData.length / samples);  // Step size
          const amp = canvas.height / 2;  // Amplitude for visualization
  
          // Clear canvas before drawing
          canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
          canvasCtx.lineWidth = 1;
          canvasCtx.strokeStyle = '#00ff00';
          canvasCtx.beginPath();
  
          for (let i = 0; i < samples; i++) {
            const min = Math.min(...rawData.slice(i * step, (i + 1) * step));
            const max = Math.max(...rawData.slice(i * step, (i + 1) * step));
            const x = (i / samples) * canvas.width;
            const yMin = (1 + min) * amp;
            const yMax = (1 + max) * amp;

            if (i === 0) {
              canvasCtx.moveTo(x, yMin);
            } else {
              canvasCtx.lineTo(x, yMin);
              canvasCtx.lineTo(x, yMax);
            }
          }
          const uniqueUrl = URL.createObjectURL(audioBlob);
          setAudioUrl(uniqueUrl);
  
          canvasCtx.stroke();
        })
        .catch((error) => {
          console.error('Failed to decode audio data:', error);
        });
    };
    reader.readAsArrayBuffer(audioBlob);

    return () => {
      audioContext.close().catch((error) => console.error('Error closing audio context:', error));
    };
  }, [audioBlob, setAudioUrl, canvasRef]);

  return null;
};

export default SpectrogramGenerator;
