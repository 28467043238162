import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import SpeechDownloadAlert from '../UI/ReuableSnackbarAlert';

const DownloadDialog = ({ transcript, audioBlob, spectrogramCanvasRef, alignment }) => {
  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);
  const [downloadTranscript, setDownloadTranscript] = useState(false);
  const [downloadAudio, setDownloadAudio] = useState(false);
  const [openAlert, setOpenAlert] = useState({ open: false, message: '', severity: 'warning' });

  const handleDownloadClick = () => {
    if (alignment !== 'stop') {
      setOpenAlert({ open: true, message: 'Please stop the recording before downloading.', severity: 'warning' });
      return;
    }

    const hasTranscript = transcript.trim() !== '';
    const hasAudio = !!audioBlob;

    const canvas = spectrogramCanvasRef.current;
    const canvasCtx = canvas?.getContext('2d');
    const hasSpectrogram = canvasCtx && canvasCtx.getImageData(0, 0, canvas.width, canvas.height).data.some(value => value !== 0);

    if (hasTranscript || hasAudio || hasSpectrogram) {
      setDownloadDialogOpen(true);
    } else {
      setOpenAlert({ open: true, message: 'There is no speech content available for download.', severity: 'info' });
    }
  };

  const handleDownloadConfirm = () => {
    if (downloadTranscript && transcript.trim() !== '') {
      const blob = new Blob([transcript], { type: 'text/plain' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'transcript.txt';
      link.click();
      URL.revokeObjectURL(url);
    }

    if (downloadAudio && audioBlob) {
      const url = URL.createObjectURL(audioBlob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'recording.wav';
      link.click();
      URL.revokeObjectURL(url);
    }

    setDownloadDialogOpen(false);
  };

  const handleDownloadDialogClose = () => {
    setDownloadDialogOpen(false);
  };

  const handleAlertClose = () => {
    setOpenAlert({ ...openAlert, open: false });
  };

  return (
    <>
      <IconButton color='primary' aria-label="speech-download" onClick={handleDownloadClick}>
        <FileDownloadOutlinedIcon style={{ color: "white" }} />
      </IconButton>

      <Dialog open={downloadDialogOpen} onClose={handleDownloadDialogClose}>
        <DialogTitle>Select Content to Download</DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={
              <Checkbox
                checked={downloadTranscript}
                onChange={(e) => setDownloadTranscript(e.target.checked)}
                color="primary"
              />
            }
            label="Transcript"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={downloadAudio}
                onChange={(e) => setDownloadAudio(e.target.checked)}
                color="primary"
              />
            }
            label="Audio Recording"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDownloadDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDownloadConfirm} color="secondary">
            Download
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for alerting the user */}
      <SpeechDownloadAlert
        open={openAlert.open}
        label={openAlert.message}
        severity={openAlert.severity}
        autoHideDuration={3000}
        onClose={handleAlertClose}
      />
    </>
  );
};

export default DownloadDialog;
