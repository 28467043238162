import React, { useEffect, useRef, useState, useCallback } from 'react';
import { getLoadedModules } from './utils/moduleLoader';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Switch from '@mui/material/Switch';
import GradientCircularProgress from './UI/GradientCircularProgress';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { ThemeProvider } from '@mui/material/styles';
import PoseDownload from './dialogs/PoseDownloadDialog';
import darkTheme from './utils/DarkTheme';
import GenerateButton from './UI/ButtonWithGradientIcon';
import Skeleton from '@mui/material/Skeleton';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import ParaphraseFormDialog from './dialogs/ParaphraseFormDialog';
import GenerateSnackbarAlert from './UI/ReuableSnackbarAlert';
import { detectEmotion, armsCrossed, handsPocket, lookingDown, headmove, eyeContact1, isSitting, drawFaceLandmarks, drawLandmarks } from './utils/detectionLogic';
import { debounce } from './utils/helpers';
import { handleFormSubmit } from './utils/apiService';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';



const BodyPoseFeedContainer = ({ alignment, setAlignment, deleteRequested, setDeleteRequested, transcript, AIsetCombinedData, AIsetLoading, changeTab, tabValue, initialAnimationComplete, modulesLoaded, setApiSnackbar, counters, setCounters, emotionData, setEmotionData}) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const poseLandmarkerRef = useRef(null);
  const faceLandmarkerRef = useRef(null);
  const webcamRunningRef = useRef(false);
  const debounceTimeoutRef = useRef(null);
  const [showPoseLandmarks, setShowPoseLandmarks] = useState(false);
  const [loading, setLoading] = useState(true);
  const [poseDetectionReady, setPoseDetectionReady] = useState(false);
  const [toggleBtnIsDisabled, setToggleBtnIsDisabled] = useState(false);

  // State for video recording and downloading
  const [rawMediaRecorder, setRawMediaRecorder] = useState(null);
  const [landmarkMediaRecorder, setLandmarkMediaRecorder] = useState(null);
  const rawChunks = useRef([]);
  const landmarkChunks = useRef([]);

  //for Prompting the user to input the presentation configuration
  const [dialogOpen, setDialogOpen] = useState(false);
  const [hasContent, setHasContent] = useState(false);
  const [genSnackBarOpen, setGenSnackBarOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('info');
  const [canvasCtx, setCanvasCtx] = useState(null);
  const [sittingStatus, setSittingStatus] = useState('Unknown');

  
  const detectionInterval = 1000; // TODO: Adjust as needed

  const captureFrame = (category) => {
    // Check if an image for this category already exists
    if (localStorage.getItem(category)) {
        return; // Do not overwrite existing image
    }
    const canvas = canvasRef.current;
    const video = videoRef.current;
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const capturedCtx = canvasCtx;
    capturedCtx.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Convert the canvas content to a data URL (base64-encoded image)
    const imageData = canvas.toDataURL('image/png');

    // Store the image data in local storage under the specified category
    localStorage.setItem(category, imageData);

    // Optionally, display the captured image on the page (for verification)
    // const img = new Image();
    // img.src = imageData;
    // document.body.appendChild(img); // Append the image to the body (or any other container)
  }

  // Debounce functions for each behavior
  const debounceArmsCrossed = useCallback(
    debounce(() => {
      setCounters((prevCounters) => {
        const updatedCount = prevCounters.armsCrossed + 1;
        console.log('Arms crossed:', updatedCount);
        return {
          ...prevCounters,
          armsCrossed: updatedCount,
        };
      });
    }, detectionInterval),
    [detectionInterval]
  );
  
  const debounceHandsInPocket = useCallback(
    debounce(() => {
      setCounters((prevCounters) => {
        const updatedCount = prevCounters.handsInPocket + 1;
        console.log('Hands in pocket:', updatedCount);
        return {
          ...prevCounters,
          handsInPocket: updatedCount,
        };
      });
    }, detectionInterval),
    [detectionInterval]
  );
  
  // Repeat the same pattern for the other counters:
  const debounceLookingDown = useCallback(
    debounce(() => {
      setCounters((prevCounters) => {
        const updatedCount = prevCounters.lookingDown + 1;
        console.log('Looking down:', updatedCount);
        return {
          ...prevCounters,
          lookingDown: updatedCount
        };
      });
    }, detectionInterval),
    [detectionInterval]
  );
  
  const debounceEyeContact = useCallback(
    debounce(() => {
      setCounters((prevCounters) => {
        const updatedCount = prevCounters.eyeContact + 1;
        console.log('Eye contact:', updatedCount);
        return {
          ...prevCounters,
          eyeContact: updatedCount,
        };
      });
    }, detectionInterval),
    [detectionInterval]
  );
  
  const debounceHeadMove = useCallback(
    debounce(() => {
      setCounters((prevCounters) => {
        const updatedCount = prevCounters.headMove + 1;
        console.log('Head move:', updatedCount);
        return {
          ...prevCounters,
          headMove: updatedCount,
        };
      });
    }, detectionInterval),
    [detectionInterval]
  );

  // Check if the iris is centered within the eye
  const isEyeContact = useCallback((irisLandmarks, eyeLandmarks) => {
    const irisCenter = calculateCenter(irisLandmarks);
    const eyeCenter = calculateCenter(eyeLandmarks);
    const threshold = 0.005; // Adjust as needed
    return Math.abs(irisCenter.x - eyeCenter.x) < threshold && Math.abs(irisCenter.y - eyeCenter.y) < threshold;
  }, []);

  // Calculate the center of a set of landmarks
  const calculateCenter = (landmarks) => {
    const x = landmarks.reduce((sum, point) => sum + point.x, 0) / landmarks.length;
    const y = landmarks.reduce((sum, point) => sum + point.y, 0) / landmarks.length;
    return { x, y };
  }

  useEffect(() => {
    if (alignment === 'start' && !showPoseLandmarks) {
      setShowPoseLandmarks(true);
    }
  }, [alignment]);

  const handleGenerateClick = () => {
    if (alignment === 'start') {
      setAlertMessage("Can't Generate during recording");
      setAlertSeverity('warning');
      setGenSnackBarOpen(true);
    } else if (!hasContent && transcript === '') {
      setAlertMessage('There is nothing to generate yet! Please record your presentation first.');
      setAlertSeverity('info');
      setGenSnackBarOpen(true);
    } else {
      setDialogOpen(true);
    }
  };

  const handleFormSubmission = (formData) => {
    handleFormSubmit(formData, transcript, changeTab, AIsetLoading, AIsetCombinedData, setApiSnackbar);
  };

  useEffect(() => {
    if (deleteRequested) {
      // Reset state
      rawChunks.current = [];
      landmarkChunks.current = [];
      setHasContent(false); // Reset hasContent when deleting content
      setDeleteRequested(false); // Reset delete request state

      localStorage.removeItem('armsCrossedCounter');
      localStorage.removeItem('handsInPocketCounter');
      localStorage.removeItem('lookingDownCounter');
      localStorage.removeItem('eyeContactCounter');
      localStorage.removeItem('headMoveCounter');

      const categories = [
        'armsCrossed',
        'handsInPocket',
        'lookingDown',
        'eyeContact',
        'headMove'
      ];

      categories.forEach(category => {
        localStorage.removeItem(category);
      });
    }
  }, [deleteRequested, setDeleteRequested]);

  useEffect(() => {
    const checkPermissionsAndSetup = async () => {
      try {
        // Check camera permission
        const camPermissionStatus = await navigator.permissions.query({ name: 'camera' });
        if (camPermissionStatus.state === 'denied') {
          alert('Camera access denied. Please enable the camera in your browser settings.');
          setLoading(true);
          return;
        }

        if (tabValue === 1) return;
  
        setLoading(true);
  
        const createPoseLandmarker = async () => {
          try {
            const modules = getLoadedModules();
            const { PoseLandmarker, FaceLandmarker, FilesetResolver } = modules;

            console.log("Initializing Landmarkers...");
            enableCam();

            console.log("Loaded modules:", modules);

            if (!PoseLandmarker || !FaceLandmarker || !FilesetResolver) {
              console.error("One or more MediaPipe modules are not available");
              setLoading(true);
              return;
            }

            if (poseLandmarkerRef.current && faceLandmarkerRef.current) {
              console.log("Landmarkers already initialized.");
              setLoading(false);
              return; // Exit if already initialized
            }

            const vision = await FilesetResolver.forVisionTasks(
              "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0/wasm"
            );
            poseLandmarkerRef.current = await PoseLandmarker.createFromOptions(vision, {
              baseOptions: {
                modelAssetPath: `https://storage.googleapis.com/mediapipe-models/pose_landmarker/pose_landmarker_lite/float16/1/pose_landmarker_lite.task`,
                delegate: "GPU"
              },
              runningMode: "VIDEO",
              numPoses: 2
            });

            faceLandmarkerRef.current = await FaceLandmarker.createFromOptions(vision, {
              baseOptions: {
                modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
                delegate: "GPU",
              },
              runningMode: 'VIDEO',
              numFaces: 1,
            });
            setCanvasCtx(canvasRef.current.getContext('2d'));
            
            setLoading(false);
          } catch (error) {
            console.error("Error initializing Landmarker:", error);
            setLoading(true);
          }
        };
  
        const enableCam = () => {
          if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
            console.warn("getUserMedia() is not supported by your browser");
            setLoading(true);
            return;
          }
  
          const constraints = { video: true };
          navigator.mediaDevices.getUserMedia(constraints)
            .then((stream) => {
              if (videoRef.current) {
                videoRef.current.pause();
                videoRef.current.srcObject = null;
                videoRef.current.srcObject = stream;
                videoRef.current.load();
  
                videoRef.current.addEventListener("loadeddata", predictWebcam);
                webcamRunningRef.current = true;
                videoRef.current.play().catch(error => {
                  console.error("Error playing video:", error);
                });
  
                setupMediaRecorders(stream);
              }
            })
            .catch((error) => {
              console.error("Error accessing webcam:", error);
              setLoading(true);
            });
        };
  
        const setupMediaRecorders = (stream) => {
          console.log("Setting up media recorders...");
          const rawRecorder = new MediaRecorder(stream);
          rawRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              rawChunks.current.push(event.data);
            }
          };
          setRawMediaRecorder(rawRecorder);
  
          const canvasStream = canvasRef.current.captureStream();
          const landmarkRecorder = new MediaRecorder(canvasStream);
          landmarkRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              landmarkChunks.current.push(event.data);
            }
          };
          setLandmarkMediaRecorder(landmarkRecorder);
        };
  
        createPoseLandmarker();
  
      } catch (error) {
        console.error("Error in setting up the pose landmarker and camera:", error);
        setLoading(true);
      }
    };
  
    checkPermissionsAndSetup();
  
    return () => {
      if (webcamRunningRef.current) {
        webcamRunningRef.current = false;
        if (videoRef.current && videoRef.current.srcObject) {
          videoRef.current.srcObject.getTracks().forEach(track => track.stop());
        }
      }
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [showPoseLandmarks,, modulesLoaded, tabValue]);

  const predictWebcam = useCallback(async () => {

    if (!canvasRef.current || !videoRef.current || !canvasCtx) {
      return;
    }

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(async () => {
      const canvas = canvasRef.current;
      const video = videoRef.current;
      const poseLandmarker = poseLandmarkerRef.current;
      const faceLandmarker = faceLandmarkerRef.current;

      if (!canvas || !video || !canvasCtx) {
        console.error('Canvas, video, or canvas context is missing.');
        return;
      }

      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;

      if (showPoseLandmarks && poseLandmarker && faceLandmarker) {
        try {
          const [results, faceResults] = await Promise.all([
            poseLandmarker.detectForVideo(video, performance.now()),
            faceLandmarker.detectForVideo(video, performance.now())
          ]);

          if (!results || !faceResults) {
            console.error('Pose or face results are missing.');
            return;
          }

          canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
          canvasCtx.drawImage(video, 0, 0, canvas.width, canvas.height);

          if (results.landmarks) {
            drawLandmarks(canvasCtx, results.landmarks);
          }
          if (faceResults.faceLandmarks) {
            drawFaceLandmarks(canvasCtx, faceResults.faceLandmarks);
          }
          
          if (alignment === 'start') {
            console.log('Detecting Pose');
            const leftIris = faceResults.faceLandmarks[0].slice(468, 473); // Left eye iris landmarks
            const rightIris = faceResults.faceLandmarks[0].slice(473, 478); // Right eye iris landmarks
            const leftEye = [faceResults.faceLandmarks[0][33], faceResults.faceLandmarks[0][133]]; // Left eye corners
            const rightEye = [faceResults.faceLandmarks[0][362], faceResults.faceLandmarks[0][263]]; // Right eye corners

            // Estimate gaze direction
            const eyeContact = isEyeContact(leftIris, leftEye) && isEyeContact(rightIris, rightEye);

            const emotion = detectEmotion(faceResults.faceLandmarks[0]);
            

            // Draw landmarks and connectors
            // Check for other behaviors and update counters
            const armsCrossedDetected = armsCrossed(results);
            const handsInPocketDetected = handsPocket(results);
            const lookingDownDetected = lookingDown(results);
            const headMoveDetected = headmove(results);
            const eyeContactDetected = eyeContact1(results);
            const sittingDetected = isSitting(results);
            const standingDetected = !sittingDetected;

            const updateSittingStatus = (sittingDetected) => {
              setSittingStatus(sittingDetected === true ? 'Sitting' : sittingDetected === false ? 'Standing' : 'Unknown');
            };

            if (armsCrossedDetected) {
              captureFrame('armsCrossed');
              debounceArmsCrossed();
            }
            if (handsInPocketDetected) {
              captureFrame('handsInPocket');
              debounceHandsInPocket();
            }
            if (lookingDownDetected) {
              captureFrame('lookingDown');
              debounceLookingDown();
            }
            if (headMoveDetected) {
              captureFrame('headMove');
              debounceHeadMove();
            }
            if (eyeContactDetected) {
              captureFrame('eyeContact');
              debounceEyeContact();
            }
          }
            
            setPoseDetectionReady(true);
        } catch (error) {
          console.error('Error during webcam prediction:', error);
        }
      } else {
        canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
        canvasCtx.drawImage(video, 0, 0, canvas.width, canvas.height);
        setPoseDetectionReady(false);
      }

      if (webcamRunningRef.current) {
        requestAnimationFrame(predictWebcam);
      }

      setLoading(false);
    }, 50);
  }, [showPoseLandmarks, debounceTimeoutRef, canvasCtx]);

  useEffect(() => {
    if (tabValue === 1) {
      setPoseDetectionReady(false);
      setShowPoseLandmarks(false);
    }
    
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
    };
  }, [tabValue, predictWebcam, debounceTimeoutRef]);
  
  const handleToggleChange = async (event, newAlignment) => {
    if (toggleBtnIsDisabled) return;
    try {
      // Check microphone permission
      const micPermissionStatus = await navigator.permissions.query({ name: 'microphone' });
      if (micPermissionStatus.state === 'denied') {
        alert('Microphone access denied. Please enable the microphone in your browser settings.');
        return;
      }

      const camPermissionStatus = await navigator.permissions.query({ name: 'camera' });
      if (camPermissionStatus.state === 'denied') {
        alert('Camera access denied. Please enable the camera in your browser settings.');
        return;
      }
      
      setToggleBtnIsDisabled(true);
      setTimeout(() => setToggleBtnIsDisabled(false), 1500);
      // If permissions are granted, proceed with the function logic
      if (newAlignment === "start") {
        if (rawMediaRecorder) rawMediaRecorder.start();
        if (landmarkMediaRecorder) landmarkMediaRecorder.start();
        setPoseDetectionReady(true);
        setHasContent(true);
      } else if (newAlignment === "stop") {
        if (rawMediaRecorder) rawMediaRecorder.stop();
        if (landmarkMediaRecorder) landmarkMediaRecorder.stop();
        setPoseDetectionReady(false);
      }
      if (newAlignment !== null) {
        setAlignment(newAlignment);
      }
    } catch (error) {
      console.error('Error checking permissions:', error);
      alert('An error occurred while checking permissions. Please try again.');
    }
  };
  
  return (
    <div className="flex flex-col bg-gradient-to-tr from-teal-800 to-gray-800 py-6 px-[33px] rounded-md h-full gap-5">
      <div className="md:flex justify-between items-center sm:hidden">
        <Switch
          checked={showPoseLandmarks}
          onChange={event => setShowPoseLandmarks(event.target.checked)}
          disabled={loading}
        />
        <h3 className="text-[22px] font-bold font-exo text-white pr-7">Your Video</h3>
        <PoseDownload
          alignment={alignment}
          rawChunks={rawChunks}
          landmarkChunks={landmarkChunks}
        >
          <FileDownloadOutlinedIcon style={{ color: "white" }} />
        </PoseDownload>
      </div>
      <div className="relative flex w-full md:h-full sm:h-full items-center justify-center ">
        {initialAnimationComplete && (
          <>
            {loading && (
              <div className="absolute inset-0 flex justify-center items-center z-10">
                <GradientCircularProgress />
              </div>
            )}
            <video 
              ref={videoRef} 
              className="absolute md:w-full sm:1/2 md:h-full rounded-md sm:h-full" 
              autoPlay 
              playsInline 
              style={{
                backgroundColor: '#0D1117',
                borderWidth: 'thin',
                borderStyle: 'solid',
                borderColor: '#4b5563'
              }} 
            />
            <canvas ref={canvasRef} className="absolute md:w-full sm:1/2 md:h-full bg-transparent rounded-md sm:h-full"></canvas>
          </>
        )}
      </div>
      <div className="pl-4 md:flex items-center gap-4 sm:hidden">
        <div 
          className={`w-3 h-3 rounded-full ${poseDetectionReady ? 'animate-blink bg-green-500' : 'bg-gray-500'}`}
          id='PoseStatusBubble'
        ></div>
        <span className="text-lg text-white">Pose Detection</span>
        <WarningAmberRoundedIcon style={{ color: 'orange' }} />
      </div>
      <div className="relative flex justify-center items-center gap-16">
        {loading ? (
          <Skeleton variant="rounder" width = '100%' height='55px' />
          ): (
            <ThemeProvider theme={darkTheme}>
              <ToggleButtonGroup
                color='primary'
                value={alignment}
                exclusive
                onChange={handleToggleChange}
                aria-label="Pose Recording"
              >
                <ToggleButton 
                  value="start"
                  sx={{
                    fontFamily: 'Helvetica Neue, Arial, sans-serif',
                    '&.Mui-selected': { 
                    color: 'lightgreen', 
                    textShadow: '1px 1px 2px black',
                  }}}
                >
                  Start
                </ToggleButton>
                <ToggleButton 
                  value="stop"
                  sx={{
                    fontFamily: 'Helvetica Neue, Arial, sans-serif',
                    '&.Mui-selected': { 
                      color: 'red',
                      textShadow: '1px 1px 2px black',
                    }}}
                >
                  Stop
                </ToggleButton>
              </ToggleButtonGroup>
              <GenerateButton 
                label="Generate" 
                IconComponent={AutoFixHighIcon} 
                gradient={true}
                onClick={handleGenerateClick}
              />
            </ThemeProvider>
          )}
      </div>
      <ParaphraseFormDialog 
        open={dialogOpen} 
        onClose={() => setDialogOpen(false)} 
        onSubmit={handleFormSubmission} 
      />
      
      <GenerateSnackbarAlert
        open={genSnackBarOpen}
        label={alertMessage}
        severity= {alertSeverity}
        autoHideDuration={3000}
        onClose={ () => setGenSnackBarOpen(false)}
      />
    </div>
  );
};

export default BodyPoseFeedContainer;
