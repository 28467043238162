const isProduction = process.env.NODE_ENV === 'production';

const config = {
  API_BASE_URL: '/api',
  // websocketUrl: isProduction ? 'ws://example.com/ws/speech-to-text/' : 'ws://localhost:8001/ws/speech-to-text/',
  clientId: process.env.REACT_APP_CLIENT_ID,
  tenantId: process.env.REACT_APP_TENANT_ID,
  redirectUri: process.env.REACT_APP_REDIRECT_URI,
  tenantName: process.env.REACT_APP_TENANT_NAME,
  signUpSignInPolicy: process.env.REACT_APP_SIGN_UP_SIGN_IN_POLICY,
  // Add other configurations here
};

export default config;