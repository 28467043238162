// DeleteDialog.js
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import DeleteSnackbarAlert from '../UI/ReuableSnackbarAlert';

const DeleteDialog = ({ open, onClose, onConfirm, snackbarOpen, setSnackbarOpen, alertMessage, alertSeverity }) => {
  return (
    <>
      <Dialog open={open} onClose={() => onClose(false)}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete the current speech content? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={() => onConfirm()} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      <DeleteSnackbarAlert
        open={snackbarOpen}
        label={alertMessage}
        severity={alertSeverity}
        autoHideDuration={3000}
        onClose={ () => setSnackbarOpen(false)}
      />
    </>
  );
};

export default DeleteDialog;
