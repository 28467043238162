import jsPDF from 'jspdf';
import Chart from 'chart.js/auto';
import { imageBase64 } from './base64Image';

export const generateReport = ({ AICombinedData, counters }) => {
    const doc = new jsPDF({
        orientation: 'p',
        unit: 'mm',
        format: 'a4'
    });

    console.log('Generating report with data:', counters, AICombinedData);

    const pageHeight = doc.internal.pageSize.height;
    const pageWidth = doc.internal.pageSize.width;
    const originalScript = AICombinedData.user_script || "No script provided"; // Added fallback
    const modifiedContent = AICombinedData.paraphrasedScript || "No paraphrased script"; // Added fallback  
    const questions = AICombinedData.generatedQuestions || "No questions provided";
    const pauseFiller = AICombinedData.pauseFillersCount || {};
    const scriptAdvice = AICombinedData.scriptAdvice || "No advice available";
    
    // Cover Page
    addPageBorder();
    doc.setFontSize(24);
    doc.text('Presentation Report', pageWidth / 2, 60, { align: 'center' });
    doc.setFontSize(16);
    doc.text('Date: ' + new Date().toLocaleDateString(), pageWidth / 2, 80, { align: 'center' });
    doc.addImage(imageBase64, 'PNG', pageWidth / 2 - 50, 120, 100, 100);
    doc.addPage();
    
    function addPageBorder() {
        const margin = 5;
        doc.setLineWidth(1);
        doc.rect(margin, margin, pageWidth - 2 * margin, pageHeight - 2 * margin);
    }
    
    function addTextSection(title, content, yPosition) {
        doc.setFontSize(18);
        doc.text(title, 10, yPosition);
        
        let titleWidth = doc.getTextWidth(title);
        doc.setLineWidth(0.5);
        doc.line(10, yPosition + 2, 10 + titleWidth, yPosition + 2);
        
        let lines = doc.splitTextToSize(content, 260);
        doc.setFontSize(12);
        doc.text(lines, 10, yPosition + 10);
        return yPosition + 10 + (lines.length * doc.internal.getLineHeight());
    }
    
    function addPageIfNeeded(extraSpace) {
        if (cur_y + extraSpace > pageHeight) {
            doc.addPage();
            cur_y = 20;
        }
    }
    
    addPageBorder();
    let cur_y = 20;
    cur_y = addTextSection('Original Script', originalScript, cur_y);
    cur_y += 10;
    addPageIfNeeded(120);

    cur_y = addTextSection('Modified Script', modifiedContent, cur_y);
    cur_y += 10;
    addPageIfNeeded(120);
    
    cur_y = addTextSection('Questions', questions, cur_y);
    cur_y += 10;
    addPageIfNeeded(120);
    
    cur_y = addTextSection('Script Advice', scriptAdvice, cur_y);
    cur_y += 10;
    
    

    
    
    // Add Charts
    addPageBorder();
    const behaviorCanvas = document.createElement('canvas');
    behaviorCanvas.id = 'behaviorChartCanvas';
    
    document.body.appendChild(behaviorCanvas);
    const behaviorCtx = behaviorCanvas.getContext('2d');
    new Chart(behaviorCtx, {
        type: 'bar',
        data: {
            labels: ['Arms Crossed', 'Shaking Head', 'Looking Down'],
            datasets: [{
                label: 'Count',
                data: [
                    counters.armsCrossed,
                    counters.headMove,
                    counters.lookingDown
                ],
                backgroundColor: ['rgba(255, 99, 132, 0.2)'],
                borderColor: ['rgba(255, 99, 132, 1)'],
                borderWidth: 1
            }]
        },
        options: {
            plugins: {
                title: {
                    display: true,
                    text: 'Behavior Mistakes',
                    font: {
                        size: 50
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        font: {
                            size: 35
                        }
                    },
                    title: {
                        display: true,
                        text: 'Behavior Types',
                        font: {
                            size: 40
                        }
                    }
                },
                y: {
                    ticks: {
                        font: {
                            size: 35
                        }
                    },
                    title: {
                        display: true,
                        text: 'Count',
                        font: {
                            size: 40
                        }
                    }
                }
            }
        }
    });
    
    const pauseFillerCanvas = document.createElement('canvas');
    pauseFillerCanvas.id = 'pauseFillerChartCanvas';
    
    document.body.appendChild(pauseFillerCanvas);
    const pauseFillerCtx = pauseFillerCanvas.getContext('2d');
    new Chart(pauseFillerCtx, {
        type: 'bar',
        data: {
            labels: ['um', 'ah', 'er', 'uh'],
            datasets: [{
                label: 'Count',
                data: [
                    pauseFiller.um,
                    pauseFiller.ah,
                    pauseFiller.er,
                    pauseFiller.uh
                ],
                backgroundColor: ['rgba(75, 192, 192, 0.2)'],
                borderColor: ['rgba(75, 192, 192, 1)'],
                borderWidth: 1
            }]
        },
        options: {
            plugins: {
                title: {
                    display: true,
                    text: 'Speech Mistakes',
                    font: {
                        size: 50
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        font: {
                            size: 35
                        }
                    },
                    title: {
                        display: true,
                        text: 'Pause Fillers',
                        font: {
                            size: 40
                        }
                    }
                },
                y: {
                    ticks: {
                        font: {
                            size: 35
                        }
                    },
                    title: {
                        display: true,
                        text: 'Count',
                        font: {
                            size: 40
                        }
                    }
                }
            }
        }
    });
    
    setTimeout(() => {
        const behaviorChartCanvas = document.getElementById('behaviorChartCanvas');
        const behaviorChartImage = behaviorChartCanvas.toDataURL('image/png');
        
        //addPageIfNeeded(120);
        doc.addPage();
        cur_y = 10;
        addPageBorder();
        doc.setFontSize(18);
        doc.text('Mistakes during the presentation', 10, 20);
        let titleWidth = doc.getTextWidth('Mistakes during the presentation');
        doc.setLineWidth(0.5);
        doc.line(10, 20 + 2, 10 + titleWidth, 20 + 2);
        
        cur_y += 20;
        doc.addImage(behaviorChartImage, 'PNG', 10, cur_y, 190, 100);
        cur_y += 120;
        
        const pauseFillerChartCanvas = document.getElementById('pauseFillerChartCanvas');
        const pauseFillerChartImage = pauseFillerChartCanvas.toDataURL('image/png');
        
        addPageIfNeeded(120);
        doc.addImage(pauseFillerChartImage, 'PNG', 10, cur_y, 190, 100);
        cur_y += 120;
        
        addPageIfNeeded(120);
        addPageBorder();
        
        const addImageWithDescription = (title, imgData, description, yPosition) => {
            if (imgData) {
                doc.setFontSize(18);
                doc.text(title, 10, yPosition);
                doc.addImage(imgData, 'PNG', 10, yPosition + 10, 60, 60);
                let lines = doc.splitTextToSize(description, 150);
                doc.setFontSize(12);
                doc.text(lines, 80, yPosition + 20);
                return yPosition + 70 + (lines.length * doc.internal.getLineHeight());
            }
            return yPosition;
        }
        
        cur_y = 20;
        cur_y = addImageWithDescription('Arms Crossed', localStorage.getItem('armsCrossed'), 'Crossing arms during a presentation can send unintended negative signals to the audience...', cur_y);
        cur_y = addImageWithDescription('Hands in Pocket', localStorage.getItem('handsInPocket'), 'Putting your hands in your pockets can signal that you are nervous or uncomfortable...', cur_y);
        cur_y = addImageWithDescription('Looking Down', localStorage.getItem('lookingDown'), 'Looking down for long periods can affect your voice projection and clarity...', cur_y);
        
        doc.save('presentation_report.pdf');
        localStorage.clear();
        
        document.body.removeChild(behaviorCanvas);
        document.body.removeChild(pauseFillerCanvas);
    }, 1000);
}
