// AudioRecorder.jsx
import { useEffect, useRef, useState } from 'react';

const AudioRecorder = ({ onRecordingComplete, alignment, analyserRef }) => {
  const audioContextRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const [isRecording, setIsRecording] = useState(false);

  useEffect(() => {
    const startRecording = async () => {
      if (!audioContextRef.current) {
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      }

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const source = audioContextRef.current.createMediaStreamSource(stream);
      if (!analyserRef.current) {
        analyserRef.current = audioContextRef.current.createAnalyser();
        analyserRef.current.fftSize = 2048;
      }
      source.connect(analyserRef.current);

      // Pass the analyser node reference to the parent
      console.log('AudioRecorder: AnalyserNode created', analyserRef.current);

      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.ondataavailable = (event) => {
        audioChunksRef.current.push(event.data);
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
        onRecordingComplete(audioBlob);
        audioChunksRef.current = [];
        setIsRecording(false);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
      console.log('AudioRecorder: Recording started');
    };

    const stopRecording = () => {
      if (mediaRecorderRef.current && isRecording) {
        mediaRecorderRef.current.stop();
        console.log('AudioRecorder: Recording stopped');
      }
    };

    if (alignment === 'start') {
      startRecording();
    } else if (alignment === 'stop') {
      stopRecording();
    }

    return () => {
      stopRecording(); // Cleanup on unmount
    };
  }, [alignment, onRecordingComplete, analyserRef]);

  return null; // This component has no UI, it's purely for logic
};

export default AudioRecorder;
