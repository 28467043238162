// moduleLoader.js
let loadedModules = {
  FaceLandmarker: null,
  PoseLandmarker: null,
  FilesetResolver: null,
  DrawingUtils: null,
};

// Function to dynamically load external scripts
const loadExternalScripts = () => {
  return new Promise((resolve, reject) => {
    const script1 = document.createElement('script');
    script1.src = 'https://cdn.jsdelivr.net/npm/@mediapipe/pose@0.4';
    script1.async = true;

    const script2 = document.createElement('script');
    script2.src = 'https://cdn.jsdelivr.net/npm/@mediapipe/drawing_utils';
    script2.async = true;

    const script3 = document.createElement('script');
    script3.src = 'https://cdn.jsdelivr.net/npm/@mediapipe/pose@0.4/pose.js';
    script3.async = true;

    // Append scripts to the document
    document.body.appendChild(script1);
    document.body.appendChild(script2);
    document.body.appendChild(script3);

    // Resolve once all scripts are loaded
    script3.onload = () => resolve();
    script3.onerror = reject;
  });
};

// Function to dynamically import MediaPipe modules
const loadMediaPipeModules = async () => {
  try {
    // Wait for external scripts to be loaded first
    await loadExternalScripts();

    // Dynamically import modules from Skypack
    const { 
      FaceLandmarker, 
      PoseLandmarker, 
      FilesetResolver,
      DrawingUtils,
    } = await import(
      'https://cdn.skypack.dev/@mediapipe/tasks-vision@0.10.0'
    );

    // Store modules in the loadedModules object
    loadedModules = {
      FaceLandmarker,
      PoseLandmarker,
      FilesetResolver,
      DrawingUtils,
    };

    console.log('MediaPipe modules loaded:', loadedModules);
  } catch (error) {
    console.error('Error loading MediaPipe modules:', error);
  }
};

// Function to get loaded modules
const getLoadedModules = () => loadedModules;

// Export functions for use in other files
export { loadMediaPipeModules, getLoadedModules };
