import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Avatar from '@mui/material/Avatar';
import { ThemeProvider } from '@mui/material/styles';
import darkTheme from './utils/DarkTheme';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import ResuableSnackbarAlert from './UI/ReuableSnackbarAlert'; 
import { Badge } from '@mui/material';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';

const Header = ({ tabValue, handleTabChange }) => {
  const { instance, accounts } = useMsal();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarLabel, setSnackbarLabel] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [isSubscribed, setIsSubscribed] = useState(false); 
  const [isInitialized, setIsInitialized] = useState(false); 

  const handleLogInClick = () => {
    if (isLoggingIn) return;

    document.body.classList.add('loading');
    setIsLoggingIn(true);

    instance.loginRedirect(loginRequest).finally(() => {
      document.body.classList.remove('loading');
      setIsLoggingIn(false);
    });
  };

  const handleLogOutClick = async () => {
    if (isLoggingOut) return;
  
    setIsLoggingOut(true);
    document.body.classList.add('loading'); // Add loading class
  
    try {
      const csrfToken = document.cookie.split('; ').find(row => row.startsWith('csrftoken=')).split('=')[1];
      // Call the backend logout endpoint

      const response = await fetch('/logout/', { // Ensure this URL points to your Django logout view
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to log out from the server.');
      }
  
      // Log out from Microsoft authentication
      await instance.logoutRedirect();
    } catch (error) {
      // Handle error (e.g., show an error message)
      setSnackbarLabel(error.message || 'Failed to log out. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      document.body.classList.remove('loading'); // Remove loading class
      setIsLoggingOut(false);
    }
  };

  useEffect(() => {
    const initializeMsal = async () => {
      try {
        await instance.initialize();
        await instance.handleRedirectPromise();
        setIsInitialized(true); // Set initialized state to true after initialization
      } catch (error) {
        console.error('Error during MSAL initialization:', error);
      }
    };
    initializeMsal();
  }, [instance]);

  useEffect(() => {
    const authenticateAndFetchData = async () => {
      if (!isInitialized) return;

      if (accounts.length > 0) {
        try {
          const account = accounts[0];
          const tokenResponse = await instance.acquireTokenSilent({
            ...loginRequest,
            account: account
          });

          const idToken = tokenResponse.idToken;

          const response = await fetch('/accounts/microsoft/login/callback/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id_token: idToken }), // Use correct token
          });

          const result = await response.json();

          if (!response.ok) {
            setSnackbarLabel(result.error || 'An error occurred.');
            setSnackbarSeverity('error');
            handleLogOutClick();
          } else {
            setSnackbarLabel(result.msg || 'Login successful!');
            setSnackbarSeverity('success');
            setIsSubscribed(result.is_subscribed);
          }
        } catch (error) {
          console.error('Network error:', error); // Log the error for debugging
          setSnackbarLabel('A network error occurred while logging you in, Please try again.');
          setSnackbarSeverity('error');
          handleLogOutClick();
        }
        setSnackbarOpen(true); // Open Snackbar after response
      } else {
        setSnackbarLabel('No Token Found, please process to login for AI features.');
        setSnackbarSeverity('info');
        setSnackbarOpen(true);
      }
    };

    // Ensure MSAL instance is initialized before making API calls
    if (instance.getAllAccounts().length > 0) {
      authenticateAndFetchData();
    } else {
      instance.handleRedirectPromise().then(authenticateAndFetchData).catch(error => {
        console.error('Error during MSAL initialization:', error);
      });
    }
  }, [instance, accounts, isInitialized]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const isLoggedIn = accounts.length > 0;
  const userEmail = isLoggedIn ? accounts[0].username : '';
  const userInitials = isLoggedIn && accounts[0].username ? accounts[0].username.split(' ').map(name => name[0]).join('') : '';

  return (
    <header className="flex flex-col top-0 bg-gray-800 sticky w-full border-b border-gray-600 z-10">
      <div id='header' className="flex justify-between items-center py-2 px-5 border-b border-gray-600">
        <img className="h-10 w-20 object-cover rounded-full" src="https://raw.githubusercontent.com/Kopi-O-Kosong-Beng/pitchMe_open/main/logo.webp" alt="PitchMeLogo" />
        <div className="flex items-center gap-10">
        <ThemeProvider theme={darkTheme}>
          {isLoggedIn ? (
            <Button 
              color="secondary"
              variant='contained'
              sx={{ padding: '4px 14px' }}
              onClick={handleLogOutClick}
            >
              Log Out
            </Button>
          ) : (
            <>
              <Button 
                variant='contained'
                sx={{ padding: '4px 14px' }}
                onClick={handleLogInClick}
              >
                Log In
              </Button>
            </>
          )}
          </ThemeProvider>
          {isLoggedIn ? (
            <div className="flex items-center gap-2">
              <Badge
                badgeContent={<DoneOutlinedIcon fontSize="4px" />}
                color="primary"
                overlap="circular"
                sx={{ 
                  '& .MuiBadge-badge': {
                    marginRight: '0.5rem',
                  },
                }}
                invisible={!isSubscribed} // Hide the badge if not subscribed
              >
                <Avatar
                  sx={{ 
                    height: 35, 
                    width: 35,
                    marginRight: 2,
                    backgroundColor: 'orange',
                  }}
                >{userInitials}</Avatar>
              </Badge>
              <span className="text-white text-sm pl-2">{userEmail}</span>
            </div>
          ) : (
            <Avatar
              sx={{ 
                height: 35, 
                width: 35,
                marginRight: 2,
              }}
            />
          )}
        </div>
      </div>
      <ThemeProvider theme={darkTheme}>
        <Tabs 
          value={tabValue} 
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
          centered
          sx={{ minHeight: 40 }}
        >
          <Tab 
            label="Main" 
            sx={{ minHeight: 40, padding: '6px 16px' }}
          />
          <Tab 
            label="Generative AI" 
            sx={{ minHeight: 40, padding: '6px 16px' }}
          />
        </Tabs>
      </ThemeProvider>
      {/* Snackbar Alert */}
      <ResuableSnackbarAlert
        open={snackbarOpen}
        label={snackbarLabel}
        severity={snackbarSeverity}
        autoHideDuration={3500}
        onClose={handleSnackbarClose}
        vertical={'top'}
        horizontal={'center'}
      />
    </header>
  );
};

export default Header;
