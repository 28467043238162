// SpeechRecognizer.jsx
import { useEffect, useRef } from 'react';

const SpeechRecognizer = ({ setTranscript, alignment }) => {
  const recognitionRef = useRef(null);

  useEffect(() => {
    if (!('webkitSpeechRecognition' in window)) {
      alert('Webkit Speech Recognition is not supported in this browser.');
      return;
    }

    recognitionRef.current = new window.webkitSpeechRecognition();
    recognitionRef.current.continuous = true;
    recognitionRef.current.interimResults = true;
    recognitionRef.current.lang = 'en-US';

    recognitionRef.current.onresult = (event) => {
      let finalTranscript = '';
      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcript = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          finalTranscript += transcript;
        }
      }
      setTranscript(prevTranscript => prevTranscript + finalTranscript);
    };

    recognitionRef.current.onerror = (event) => {
      alert('Speech recognition error:', event.error);
    };

    recognitionRef.current.onend = () => {
      console.log('Speech recognition ended.');
    };

    if (alignment === 'start') {
      recognitionRef.current.start();
    } else if (alignment === 'stop') {
      recognitionRef.current.stop();
    }

    return () => {
      recognitionRef.current.stop(); // Cleanup on unmount
    };
  }, [setTranscript, alignment]);

  return null;
};

export default SpeechRecognizer;
