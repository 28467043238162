import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, MenuItem } from '@mui/material';

const ParaphraseFormDialog = ({ open, onClose, onSubmit }) => {
  const [targetUser, setRole] = useState('');
  const [occupation, setOccupation] = useState('');
  const [scenario, setScenario] = useState('');
  const [specifyScenario, setSpecifyScenario] = useState('');
  const [purpose, setGoal] = useState('');

  const handleSubmit = () => {
    // Determine the final values for targetUser and scenario
    const finalTargetUser = targetUser === 'Working Professional' && occupation ? occupation : targetUser;
    const finalScenario = scenario === 'Other' && specifyScenario ? specifyScenario : scenario;

    if (finalTargetUser && finalScenario && purpose) {
      onSubmit({ targetUser: finalTargetUser, scenario: finalScenario, purpose });
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>For your Presentation</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="What is your primary role?"
          select
          fullWidth
          variant="outlined"
          value={targetUser}
          onChange={(e) => setRole(e.target.value)}
        >
          <MenuItem value="Student">Student</MenuItem>
          <MenuItem value="Working Professional">Working Professional</MenuItem>
          <MenuItem value="Unemployed">Unemployed</MenuItem>
        </TextField>
        {targetUser === 'Working Professional' && (
          <TextField
            margin="dense"
            label="Please specify your occupation"
            fullWidth
            variant="outlined"
            value={occupation}
            onChange={(e) => setOccupation(e.target.value)}
          />
        )}
        <TextField
          margin="dense"
          label="Choose the best option that describes your presentation scenario"
          select
          fullWidth
          variant="outlined"
          value={scenario}
          onChange={(e) => setScenario(e.target.value)}
        >
          <MenuItem value="Classroom presentation">Classroom presentation</MenuItem>
          <MenuItem value="Business meeting">Business meeting</MenuItem>
          <MenuItem value="Conference presentation">Conference presentation</MenuItem>
          <MenuItem value="Public speech">Public speech</MenuItem>
          <MenuItem value="Other">Other (please specify)</MenuItem>
        </TextField>
        {scenario === 'Other' && (
          <TextField
            margin="dense"
            label="Please specify your Presentation Scenario"
            fullWidth
            variant="outlined"
            value={specifyScenario}
            onChange={(e) => setSpecifyScenario(e.target.value)}
          />
        )}
        <TextField
          margin="dense"
          label="What is the main goal of your presentation?"
          fullWidth
          variant="outlined"
          value={purpose}
          onChange={(e) => setGoal(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="secondary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ParaphraseFormDialog;
