import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import AppPage from './components/AppPage'; // Your main app component

const App = () => {

  return (
    <Router>
      <Routes>
        <Route path="/" element={<AppPage />} /> {/* Default route */}
      </Routes>
    </Router>
  );
};

export default App;