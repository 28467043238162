import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import PoseDownloadAlert from '../UI/ReuableSnackbarAlert';

const DownloadButton = ({ alignment, rawChunks, landmarkChunks }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedVideos, setSelectedVideos] = useState({
    raw: false,
    landmark: false,
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('info');

  const handleDownloadClick = () => {
    const isRecording = alignment === "start";
    const hasRecordedVideo = rawChunks.current.length > 0 || landmarkChunks.current.length > 0;

    if (isRecording) {
      setAlertMessage("Recording is still in progress.");
      setAlertSeverity('warning');
      setSnackbarOpen(true);
    } else if (!hasRecordedVideo) {
      setAlertMessage("No video recorded yet.");
      setAlertSeverity('info');
      setSnackbarOpen(true);
    } else {
      setOpenDialog(true);
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false); // Closes the dialog
  };


  const handleVideoSelection = (event) => {
    setSelectedVideos({ ...selectedVideos, [event.target.name]: event.target.checked });
  };

  const handleDownloadConfirmation = () => {
    if (selectedVideos.raw) {
      const rawBlob = new Blob(rawChunks.current, { type: 'video/webm' });
      const rawUrl = URL.createObjectURL(rawBlob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = rawUrl;
      a.download = 'raw_video.webm';
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(rawUrl);
    }

    if (selectedVideos.landmark) {
      const landmarkBlob = new Blob(landmarkChunks.current, { type: 'video/webm' });
      const landmarkUrl = URL.createObjectURL(landmarkBlob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = landmarkUrl;
      a.download = 'landmark_video.webm';
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(landmarkUrl);
    }

    handleDialogClose();
  };

  return (
    <div>
      <IconButton color='primary' onClick={handleDownloadClick}>
        <FileDownloadOutlinedIcon style={{ color: "white" }} />
      </IconButton>
      
      {/* Download Dialog Pop-Up Box */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>Select Videos to Download</DialogTitle>
        <DialogContent>
          <Checkbox
            checked={selectedVideos.raw}
            onChange={handleVideoSelection}
            name="raw"
            color="primary"
          />
          Raw Camera Feed
          <br />
          <Checkbox
            checked={selectedVideos.landmark}
            onChange={handleVideoSelection}
            name="landmark"
            color="primary"
          />
          Camera Feed with Pose Landmarks
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleDownloadConfirmation} color="secondary">
            Download
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for alerting the user */}
      <PoseDownloadAlert
        open={snackbarOpen}
        label={alertMessage}
        severity={alertSeverity}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      />
    </div>
  );
};

export default DownloadButton;
