export const calculateRatio = (point1, point2, point3, point4) => {
  // Calculate ratio of distances between points
  const distance1 = calculateDistance(point1, point2);
  const distance2 = calculateDistance(point3, point4);
  return distance1 / distance2;
};

// Calculate Euclidean distance between two points
const calculateDistance = (point1, point2) => {
  return Math.sqrt((point1.x - point2.x) ** 2 + (point1.y - point2.y) ** 2);
};

export const debounce = (func, delay, timer) => {
  return function () {
      if (timer) {
          return; // If the timer is active, do nothing
      }
      const context = this;
      const args = arguments;
      timer = setTimeout(() => {
          func.apply(context, args);
          timer = null; // Reset the timer after execution
      }, delay);
  };
};

