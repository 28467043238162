import React, { useEffect, useRef, useState } from 'react';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import IconButton from '@mui/material/IconButton';
import GradientCircularProgress from './UI/GradientCircularProgress';
import ReactAudioPlayer from 'react-audio-player';
import SpeechDownload from './dialogs/SpeechDownloadDialog';
import DeleteDialog from './dialogs/DeleteDialog';
import AudioRecorder from './Audio/AudioRecorder';
import SpectrogramGenerator from './Audio/SpectogramGenerator';
import SpeechRecognizer from './Audio/SpeechRecognizer';
import WaveformVisualizer from './Audio/WaveformVisualizer';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import Badge from '@mui/material/Badge';

const SpeechContent = ({ alignment, onDeleteRequest, transcript, setTranscript}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const spectrogramCanvasRef = useRef(null);
  const progressBarRef = useRef(null);
  const [audioUrl, setAudioUrl] = useState('');
  const prevAudioUrlRef = useRef('');
  const analyserRef = useRef(null);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('info');

  const handleDialogOpen = () => {
    // Check if the transcript is not empty
    const hasTranscript = transcript.trim() !== '';
    
    // Check if the spectrogram canvas has content
    const canvas = spectrogramCanvasRef.current;
    const canvasCtx = canvas?.getContext('2d');
    const hasSpectrogram = canvasCtx && canvasCtx.getImageData(0, 0, canvas.width, canvas.height).data.some(value => value !== 0);
  
    // Open the dialog if either the transcript or spectrogram content exists
    if (alignment === 'start') {
      setAlertMessage("Can't delete during recording");
      setAlertSeverity('warning');
      setShowAlert(true);
    } else if (!hasTranscript && !hasSpectrogram) {
      setAlertMessage('There is nothing to delete');
      setAlertSeverity('info');
      setShowAlert(true);
    } else {
      setDialogOpen(true);
    }
  };


  const handleDialogClose = (confirm) => {
    if (confirm) {
      setTranscript('');
      setAudioBlob(null); // Clear audio blob
      console.log('Deleting audio blob:', audioBlob);

      if (prevAudioUrlRef.current) {
        URL.revokeObjectURL(prevAudioUrlRef.current);
        prevAudioUrlRef.current = ''; // Clear the reference
      }
      setAudioUrl(''); // Reset the audio player

      if (spectrogramCanvasRef.current) {
        const canvasCtx = spectrogramCanvasRef.current.getContext('2d');
        canvasCtx.clearRect(0, 0, spectrogramCanvasRef.current.width, spectrogramCanvasRef.current.height); // Clear the spectrogram canvas
      }
      onDeleteRequest(); // Notify parent to delete video
    }
    setDialogOpen(false);
  };

  const handleRecordingComplete = (newBlob) => {
    setAudioChunks((prevChunks) => [...prevChunks, newBlob]); // Append the new chunk to the existing chunks
  };

  useEffect(() => {
    if (alignment === 'stop' && audioChunks.length > 0) {
      const combinedBlob = new Blob(audioChunks, { type: 'audio/wav' });
      setAudioBlob(combinedBlob);

      const uniqueUrl = URL.createObjectURL(combinedBlob);
      setAudioUrl(uniqueUrl);
      prevAudioUrlRef.current = uniqueUrl; // Save the URL reference
    }
  }, [alignment, audioChunks]);

  useEffect(() => {
    if (alignment === 'start') {
      setLoading(false); 
    }
  }, [alignment]);

  return (
    <div className="flex flex-col bg-gradient-to-tr h-full from-teal-800 to-gray-800 px-[33px] py-6 rounded-md gap-5 ">
      <AudioRecorder 
        onRecordingComplete={handleRecordingComplete} 
        alignment={alignment} 
        analyserRef={analyserRef} 
      />
      <SpeechRecognizer setTranscript={setTranscript} alignment={alignment} />
      <div className="md:flex justify-between items-center sm:hidden">
        <IconButton color="error" aria-label="delete" onClick={handleDialogOpen}>
          <DeleteIcon style={{ color: 'white' }} />
        </IconButton>
        <h3 className="text-[22px] font-bold font-exo text-white">Your Speech</h3>
        {/* Use the DownloadDialog component */}
        <SpeechDownload 
          transcript={transcript} 
          audioBlob={audioBlob} 
          spectrogramCanvasRef={spectrogramCanvasRef} 
          alignment={alignment}
        />
      </div>
      <div className="relative w-full md:h-full sm:h-[140px]">
        {loading && (
          <div className="absolute inset-0 flex justify-center items-center z-10">
            <GradientCircularProgress />
          </div>
        )}
        <textarea
          id="convert_text"
          cols="29"
          className=" w-full text-white outline-none h-full"
          value={transcript}
          readOnly
        />
      </div>
      <div 
        className="relative rounded-[35px] px-1 pt-1 w-full" 
        style={{backgroundColor: '#3D3D3D'}}
      >
        <Badge 
          color="success" 
          badgeContent=" "
          overlap="rectangular"
          invisible={alignment === 'stop'}
          sx={{ 
            width: '100%', 
            '& .MuiBadge-badge': {
              marginRight: '2rem',
              borderColor: 'grey',
              borderWidth: 1,
            },
          }}
        >
          <canvas
            ref={spectrogramCanvasRef}
            id="spectrogram"
            className="w-full h-[75px] rounded-[35px]"
            style={{
              backgroundColor: '#0D1117',
              borderWidth: 'thin',
              borderStyle: 'solid',
              borderColor: '#4b5563'
            }}
          />
          {alignment === 'start' && analyserRef.current && (
            <WaveformVisualizer 
              analyserRef={analyserRef} 
              isActive={alignment === 'start'} 
              canvasRef={spectrogramCanvasRef}
            />
          )}
          {alignment === 'stop' && audioBlob && (
            <SpectrogramGenerator 
              audioBlob={audioBlob} 
              setAudioUrl={setAudioUrl} 
              canvasRef={spectrogramCanvasRef}
            />
          )}
        </Badge>
        <div className='absolute top-1 left-0 w-full h-full px-1'>
          <canvas
            ref={progressBarRef}
            id="progressBar"
            className="top-0 left-0 w-full h-[75px] rounded-[35px] bg-red-600 bg-opacity-20 hidden"
          />
        </div>
        <div className="flex justify-center px-3">
          <ReactAudioPlayer 
            key={audioUrl}
            id="audio" 
            src={audioUrl}
            controls
            className="w-full h-9"
          />
        </div>
      </div>
      <div className="md:flex flex-col gap-6 sm:hidden">
        <div className="pl-4 flex items-center gap-4">
          <div 
            className={`w-3 h-3 rounded-full ${
              alignment === 'start' ? 'animate-blink bg-green-500' : 'bg-gray-500'
            }`}
          />
          <span className="text-lg text-white">Pause Filler Detection</span>
          <WarningAmberRoundedIcon style={{ color: 'orange' }} />
        </div>
        <div className="pl-4 flex items-center gap-4">
          <div 
            id="Speech2TextBubble" 
            className={`w-3 h-3 rounded-full ${
              alignment === 'start' ? 'animate-blink bg-green-500' : 'bg-gray-500'
            }`}
          />
          <span className="text-lg text-white">Speech To Text Recognition</span>
        </div>
      </div>

      {/* Alert Dialog */}
      <DeleteDialog 
        open={dialogOpen}
        onClose={handleDialogClose}
        onConfirm={() => handleDialogClose(true)}
        snackbarOpen={showAlert}
        setSnackbarOpen={setShowAlert}
        alertMessage={alertMessage}
        alertSeverity={alertSeverity}
      />

    </div>
  );
};

export default SpeechContent;
