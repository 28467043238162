// utils/formHandlers.js
import config from '../../config'; // Adjust path based on your project structure
/**
 * Handles the form submission process by making API calls and processing responses.
 * @param {Object} formData - The form data to be submitted.
 * @param {string} transcript - The transcript text to be included in the request.
 * @param {Function} changeTab - Function to switch tabs in the UI.
 * @param {Function} AIsetLoading - Function to update loading state.
 * @param {Function} AIsetCombinedData - Function to set combined data after successful API responses.
 * @param {Function} setApiSnackbar - Function to set Snackbar state (open, label, severity)
 */
export const handleFormSubmit = (
  formData,
  transcript,
  changeTab,
  AIsetLoading,
  AIsetCombinedData,
  setApiSnackbar
) => {
  const csrftoken = getCookie('csrftoken');

  console.log('Form Data:', formData);
  console.log('Transcript:', transcript);
  changeTab(1);

  AIsetLoading({
    scriptLoading: true,
    questionLoading: true,
  });

  // Prepare data for the APIs
  const requestData = {
    target_user: formData.targetUser,
    presenting_scenario: formData.scenario,
    purpose: formData.purpose,
    user_script: transcript,
  };

  // Make both API calls concurrently
  const paraphraseRequest = fetch(`${config.API_BASE_URL}/paraphrase-script/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': csrftoken,
    },
    body: JSON.stringify(requestData),
  });

  const questionRequest = fetch(`${config.API_BASE_URL}/generate-questions/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestData),
  });

  // Handle both responses when they are ready
  Promise.all([paraphraseRequest, questionRequest])
    .then(async ([paraphraseResponse, questionResponse]) => {

      if (!paraphraseResponse.ok) {
        const errorData = await paraphraseResponse.json();
        throw new Error(`${errorData.error || paraphraseResponse.statusText}`);
      }
      if (!questionResponse.ok) {
        const errorData = await questionResponse.json();
        throw new Error(`${errorData.error || questionResponse.statusText}`);
      }

      const paraphraseData = await paraphraseResponse.json();
      const questionData = await questionResponse.json();

      console.log('Paraphrased Script:', paraphraseData.paraphrased_script);
      console.log('Generated Questions:', questionData.questions);

      // Check if questionData.questions is a string
      let formattedQuestions = [];
      if (typeof questionData.questions === 'string') {
        formattedQuestions = questionData.questions
          .replace(/\*/g, '')
          .split('\n')
          .filter((q) => q.trim() !== '')
          .join('\n');
      } else if (Array.isArray(questionData.questions)) {
        formattedQuestions = questionData.questions.join('\n');
      }

      // Clean and set the paraphrased script
      const cleanedScript = paraphraseData.paraphrased_script
        .replace(/\\n/g, '\n')
        .replace(/^"(.*)"$/, '$1');

      const cleanedAdvice = paraphraseData.script_advice
        .replace(/\\n/g, '\n')
        .replace(/^"(.*)"$/, '$1');

      const cleanedPauseFillersCount = {};

      if (
        typeof paraphraseData.pause_fillers_count === 'object' &&
        paraphraseData.pause_fillers_count !== null
      ) {
        for (const [filler, count] of Object.entries(
          paraphraseData.pause_fillers_count
        )) {
          if (typeof count === 'number' && !isNaN(count)) {
            cleanedPauseFillersCount[filler] = count;
          }
        }
      }

      const combinedData = {
        target_user: formData.targetUser,
        presenting_scenario: formData.scenario,
        purpose: formData.purpose,
        user_script: transcript,
        paraphrasedScript: cleanedScript,
        generatedQuestions: formattedQuestions,
        pauseFillersCount: cleanedPauseFillersCount,
        scriptAdvice: cleanedAdvice,
      };
      AIsetCombinedData(combinedData);

      setApiSnackbar({
        open: true,
        label: 'Data processed successfully!',
        severity: 'success',
      });
    })
    .catch((error) => {
      console.error('Error:', error);
      setApiSnackbar({
        open: true,
        label: `Error: ${error.message}`,
        severity: 'error',
      });
    })
    .finally(() => {
      AIsetLoading({
        questionLoading: false,
        scriptLoading: false,
      });
    });
};

const getCookie = (name) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};
