import { LogLevel } from "@azure/msal-browser";
import config from "./config";

console.log("Client ID:", config.clientId);
console.log("Authority:", `https://${config.tenantName}.b2clogin.com/${config.tenantName}.onmicrosoft.com/${config.signUpSignInPolicy}`);
console.log("Redirect URI:", config.redirectUri);
console.log("Known Authorities:", [`${config.tenantName}.b2clogin.com`]);

const msalConfig = {
  auth: {
    clientId: config.clientId,
    authority: `https://${config.tenantName}.b2clogin.com/${config.tenantName}.onmicrosoft.com/${config.signUpSignInPolicy}`,
    redirectUri: config.redirectUri,
    knownAuthorities: [`${config.tenantName}.b2clogin.com`],
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            break;
          case LogLevel.Info:
            console.info(message);
            break;
          case LogLevel.Verbose:
            console.debug(message);
            break;
          case LogLevel.Warning:
            console.warn(message);
            break;
          default:
            console.log(message);
            break;
        }
      },
      LogLevel: LogLevel.Verbose,
    },
  },
};

const loginRequest = {
  scopes: ["openid", "profile", "email"],
};

export { msalConfig, loginRequest };