import { calculateRatio } from './helpers';
import { getLoadedModules } from './moduleLoader';

export const drawLandmarks = (canvasCtx, landmarks) => {
  const { DrawingUtils, PoseLandmarker } = getLoadedModules();

  if (!DrawingUtils || !PoseLandmarker) {
    console.error('MediaPipe modules are not loaded');
    return;
  }

  const drawingUtils = new DrawingUtils(canvasCtx);
  landmarks.forEach(landmark => {
    drawingUtils.drawLandmarks(landmark, {
      color: 'red',
      radius: data => DrawingUtils.lerp(data.from.z, -0.15, 0.1, 1.5, 0.5)
    });
    drawingUtils.drawConnectors(landmark, PoseLandmarker.POSE_CONNECTIONS, { color: 'Magenta', lineWidth: 1.5 });
  });
};

export const drawFaceLandmarks = (canvasCtx, faceLandmarks) => { 
  const { DrawingUtils, FaceLandmarker } = getLoadedModules();

  if (!DrawingUtils || !FaceLandmarker) {
    console.error('MediaPipe modules are not loaded');
    return;
  }

  const drawingUtils = new DrawingUtils(canvasCtx);
  faceLandmarks.forEach(landmarks => {
    drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_TESSELATION, { color: "#C0C0C060", lineWidth: 1 });
    drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_RIGHT_EYE, { color: "#FF3030", lineWidth: 1 });
    drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_LEFT_EYE, { color: "#30FF30", lineWidth: 1 });
    drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_FACE_OVAL, { color: "#E0E0E0", lineWidth: 1 });
    drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_LIPS, { color: "#E0E0E0", lineWidth: 1 });
    drawingUtils.drawConnectors(landmarks, FaceLandmarker.FACE_LANDMARKS_RIGHT_IRIS, { color: "#E0E0E0", lineWidth: 1 });
  });
};

export const detectEmotion = (landmarks) => {
  // Extract key landmarks
  const mouth = landmarks.slice(78, 88); // Mouth landmarks (approximate indices)
  const leftEyebrow = landmarks.slice(105, 113); // Left eyebrow landmarks (approximate indices)
  const rightEyebrow = landmarks.slice(158, 166); // Right eyebrow landmarks (approximate indices)

  // Calculate ratios
  const mouthRatio = calculateRatio(mouth[3], mouth[9], mouth[0], mouth[6]);
  const eyebrowRatio = calculateRatio(leftEyebrow[0], leftEyebrow[4], rightEyebrow[0], rightEyebrow[4]);

  // Heuristic rules for detecting happiness or sadness
  const isHappy = mouthRatio > 0.3 ;
  const isSad = mouthRatio < 0.2;

  if (isHappy) {
    return 'Happy';
  } else if (isSad) {
    return 'Sad';
  } else {
    return 'Neutral';
  }
};

// Function to detect if arms are crossed
export const armsCrossed = (results) => {
  if (results.landmarks) {
      const leftWrist = results.landmarks[0][15];
      const rightWrist = results.landmarks[0][16];
      const leftElbow = results.landmarks[0][13];
      const rightElbow = results.landmarks[0][14];

      if (Math.abs(leftWrist.x - rightElbow.x) <= 0.15 || Math.abs(rightWrist.x - leftElbow.x) <= 0.15) {
          return true; // Arms crossed
      }
  }
  return false; // Arms not crossed
};

// Function to detect if hands are in pockets
export const handsPocket = (results) => {
  if (results.landmarks) {
      const leftThumb = results.landmarks[0][21];
      const rightThumb = results.landmarks[0][22];
      const leftHip = results.landmarks[0][23];
      const rightHip = results.landmarks[0][24];

      if ((Math.abs(leftThumb.x - leftHip.x) <= 0.04 && Math.abs(leftThumb.y - leftHip.y) <= 0.3) ||
          (Math.abs(rightThumb.x - rightHip.x) <= 0.04 && Math.abs(rightThumb.y - rightHip.y) <= 0.3)) {
          return true;
      }
  }
  return false;
};

// Function to detect if looking down
export const lookingDown = (results) => {
  if (results.landmarks) {
      const leftEye = results.landmarks[0][2];
      const rightEye = results.landmarks[0][5];
      const leftEar = results.landmarks[0][7];
      const rightEar = results.landmarks[0][8];

      if (Math.abs(leftEye.y - leftEar.y) < 0.004 || Math.abs(rightEye.y - rightEar.y) < 0.004) {
          return true;
      }
  }
  return false;
};

// Function to detect head movement
export const headmove = (results) => {
  if (results.landmarks) {
      const leftMouth = results.landmarks[0][9];
      const rightMouth = results.landmarks[0][10];
      const leftShoulder = results.landmarks[0][11];
      const rightShoulder = results.landmarks[0][12];

      // Check if the mouth has moved significantly relative to the shoulders
      const mouthMovementThreshold = 0.05;
      const shoulderMovementThreshold = 0.05;

      const isMouthMoving = Math.abs(leftMouth.x - leftShoulder.x) < mouthMovementThreshold;
      const isShoulderMoving = Math.abs(rightMouth.x - rightShoulder.x) < shoulderMovementThreshold;

      return isMouthMoving || isShoulderMoving;
  }
  return false;
};

// Function to detect eye contact
export const eyeContact1 = (results) => {
  if (results.landmarks) {
      const leftEye = results.landmarks[0][2];
      const rightEye = results.landmarks[0][5];
      const nose = results.landmarks[0][0]; // Using nose as a reference point

      // Assuming eye contact means both eyes are within a certain range from the nose
      const eyeContactThreshold = 0.05;

      if (Math.abs(leftEye.x - nose.x) < eyeContactThreshold && Math.abs(rightEye.x - nose.x) < eyeContactThreshold) {
          return true; // Eye contact detected
      }
  }
  return false;
};

export const isSitting = (results) => {
  if (results.landmarks) {
      const leftShoulder = results.landmarks[0][11];
      const rightShoulder = results.landmarks[0][12];
      const leftHip = results.landmarks[0][23];
      const rightHip = results.landmarks[0][24];
      const leftKnee = results.landmarks[0][25];
      const rightKnee = results.landmarks[0][26];

      // Calculate distances
      const shoulderToHipDistance = Math.abs(leftShoulder.y - leftHip.y) + Math.abs(rightShoulder.y - rightHip.y);
      const kneeToHipDistance = Math.abs(leftKnee.y - leftHip.y) + Math.abs(rightKnee.y - rightHip.y);

      // Define thresholds for sitting and standing
      const sittingThreshold = 0.4;
      const standingThreshold = 0.05;

      if (kneeToHipDistance < sittingThreshold) {
          return true; // Likely sitting
      } else if (kneeToHipDistance >= sittingThreshold) {
          return false; // Likely standing
      }
  }
  return null; // Unknown posture
};